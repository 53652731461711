import Link from 'next/link';

import AuthenticationForm from '../client/components/AuthenticationForm';

function Login() {
  return (
    <>
      <div className="flex-1 flex flex-col items-center justify-center h-full">
        <h1 className="text-4xl font-bold mb-8 text-center font-serif">
          Welcome back!
          <br />
          Sign in to your Stackblocks account.
        </h1>
        <AuthenticationForm />
        <div className="mt-8 text-gray-600 text-base">
          New here?{' '}
          <Link href="/get-started" className="underline">
            Signup
          </Link>{' '}
        </div>
      </div>
    </>
  );
}

export default Login;
