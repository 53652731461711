import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { MailIcon } from '@heroicons/react/solid';
import * as Sentry from '@sentry/nextjs';
import * as Amplitude from '@amplitude/analytics-browser';

import GoogleIcon from '../Icons/Google';

/**
 * Used on the Login and Sign Up screens to handle authentication. Can be shared between those as Passport.js doesn't differentiate between logging in and signing up.
 */
export default function AuthenticationForm() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const router = useRouter();
  const { r, error: queryError } = router.query;
  const redirect = r?.toString();

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'cf-turnstile-callback';
    script.innerHTML = `window.onloadTurnstileCallback = function () {
          window.turnstile.render('#turnstile-widget', {
            sitekey: '${process.env.NEXT_PUBLIC_CF_TURNSTILE_SITE_KEY}',
          })
        }`;
    document.head.appendChild(script);

    const tsScript = document.createElement('script');
    tsScript.src = `https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback`;
    tsScript.async = true;
    tsScript.defer = true;
    document.head.appendChild(tsScript);
  }, []);

  useEffect(() => {
    if (queryError) {
      setError(queryError.toString());
    }
  }, [queryError]);

  const handleGoogleSignIn = () => {
    Amplitude.track('Login', { provider: 'google' });
    const googleAuthUrl = '/api/auth/google';
    if (redirect) {
      window.location.href = `${googleAuthUrl}?redirect=${encodeURIComponent(redirect)}`;
    } else {
      window.location.href = googleAuthUrl;
    }
  };

  return (
    <>
      <div className="w-full max-w-sm space-y-4">
        {error && <div className=" my-6 text-center text-red-600/80">{error}</div>}

        <button
          type="button"
          onClick={handleGoogleSignIn}
          className="mb-12 w-full flex gap-4 items-center justify-center px-4 py-2 border border-gray-300 shadow-sm  font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <GoogleIcon />
          Sign in with Google
        </button>
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center ">
            <span className="px-2 bg-white text-gray-500">Or continue with email</span>
          </div>
        </div>

        <form
          className="space-y-4"
          onSubmit={async (evt) => {
            evt.preventDefault();

            const formData = new FormData(evt.target as HTMLFormElement);
            const token = formData.get('cf-turnstile-response');

            try {
              const res = await fetch(`/api/auth/magiclink`, {
                method: `POST`,
                body: JSON.stringify({
                  redirect,
                  destination: email,
                  token,
                }),
                headers: { 'Content-Type': 'application/json' },
              });
              if (res.status === 429) {
                setError(
                  `Slow down there, champ! You’re making too many requests. Please wait a bit and try again. If the problem persists, contact our friendly support team.`
                );
                return;
              }
              if (res.status === 500) {
                setError(`Something went wrong human. Please try again.`);
                return;
              }
              const json = await res.json();
              if (json.success) {
                Amplitude.track('Login', { provider: 'email' });

                // Add the email and security code to the query params so we can show them on the /check-mailbox page
                router.push(`/check-mailbox?e=${encodeURIComponent(email)}&c=${json.code}`);
              }
            } catch (err) {
              Sentry.captureException(err);
            }
          }}
        >
          <div className="rounded-md shadow-sm w-full">
            <label htmlFor="email-address" className="sr-only">
              Email
            </label>
            <input
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-orange-500 focus:border-orange-500 focus:z-10 "
              placeholder="me@hello.com"
              value={email}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </div>

          <div id="turnstile-widget" className="mt-4 mx-auto flex justify-center" />

          <button
            className="mt-4 group relative w-full flex justify-center py-2 px-4 border border-transparent  font-medium rounded-md text-orange-100 bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            type="submit"
          >
            <span className="absolute left-0 inset-y-0 flex items-center pl-3">
              <MailIcon
                className="h-5 w-5 text-orange-400 group-hover:text-orange-300"
                aria-hidden="true"
              />
            </span>
            Send Login Link&nbsp;🪄
          </button>
        </form>
      </div>
    </>
  );
}
